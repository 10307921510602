import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "@shared/seo";
import { Query, SanityBrandConnection } from "@graphql-types";
import { BrandOrRangeDisplay } from "@sections";
import Layout from "@shared/layout";

interface Data extends Query {
  altRegions: SanityBrandConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function brandTemplate({ data }: Props) {
  if (data.sanityBrand == null) return null;

  const { sanityBrand, altRegions } = data;
  const { brandRanges, slug } = data.sanityBrand;
  const altRegionsOrganised = altRegions.nodes.map(alt => alt.region);

  return (
    <>
      <SEO
        seoData={sanityBrand?.seo}
        slug={sanityBrand?.slug?.current as string}
        alternateRegions={altRegionsOrganised}
        currentIban={sanityBrand?.region?.iban}
      />
      <Layout page={slug?.current}>
        <BrandOrRangeDisplay data={brandRanges} usePaginationOverride useH1Setup />
      </Layout>
    </>
  );
}

export const query = graphql`
  query Brand($slug: String, $iban: String) {
    sanityBrand(slug: { current: { eq: $slug } }, region: { iban: { eq: $iban } }) {
      seo {
        ...sanitySeo
      }
      title
      region {
        iban
      }
      slug {
        current
      }
      brandRanges {
        ...sanityBrandOrRangeList
      }
    }
    altRegions: allSanityBrand(
      filter: { slug: { current: { eq: $slug } }, isHidden: { ne: true } }
    ) {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
